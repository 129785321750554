import React, { useState } from "react";
import PropTypes from "prop-types";
//import { render } from 'react-dom';
import { AgGridColumn, AgGridReact } from "ag-grid-react";

import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";

//$ag-suppress-legacy-var-import: true;

import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "ag-grid-community/dist/styles/ag-theme-balham-dark.css";
import "ag-grid-community/dist/styles/ag-theme-blue.css";
import "ag-grid-community/dist/styles/ag-theme-fresh.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "ag-grid-community/dist/styles/ag-theme-bootstrap.css";
import "ag-grid-community/dist/styles/ag-theme-dark.css";
//import "ag-grid-community/dist/styles/ag-theme-classic.css";
//import "ag-grid-community/dist/styles/ag-theme-base.css";




import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import Switch from '@material-ui/core/Switch';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import Tooltip from '@material-ui/core/Tooltip';
import Fade from '@material-ui/core/Fade';
import Zoom from '@material-ui/core/Zoom';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';


import { spacing } from '@material-ui/system';


import ButtonGroup from "@material-ui/core/ButtonGroup";
import { createStyles, createMuiTheme, createTheme, MuiThemeProvider, makeStyles, ThemeProvider, rgbToHex } from '@material-ui/core/styles';
//import darkBaseTheme from '@material-ui/';
//import { darkBaseTheme } from "@material-ui/core/styles";
//import { withStyles } from "@material-ui/core/styles";
//import { makeStyles } from "@material-ui/core/styles";

import { useRef, useEffect } from "react";
// import PropTypes from "prop-types";

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { red } from "@material-ui/core/colors";
import { LaptopWindows, SportsRugbySharp } from "@material-ui/icons";

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from "@material-ui/core/CircularProgress";




function useOutsideAlerter(ref) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {

        var signinPopup = document.getElementById("popup_box");
        var signinButton = document.getElementById("logonbutton");
        signinPopup.style.visibility = "hidden";
        signinButton.style.visibility = "visible";
        signinPopup.style.display = "none";
        signinButton.style.display = "block";
        //Using 'display:none', 'display:block' instead of 'visibility:hidden', 'visibility:visible' on your parent div should solve the problem.
        // alert("You clicked outside of me!");
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);

    };
  }, [ref]);
}

/**
 * Component that alerts if you click outside of it
 */
function OutsideAlerter(props) {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return <div ref={wrapperRef}>{props.children}</div>;
}

OutsideAlerter.propTypes = {
  children: PropTypes.element.isRequired
};





//import React, { useState } from "react";
//import ReactModalLogin from "../react-modal-login";
//import { facebookConfig, googleConfig } from "./social-config";





//import { GoogleLogin, GoogleLogout } from 'react-google-login';
//const clientId = "381676831704-krmph7jg7ujlhidj3jhn41a8lc54j9ka.apps.googleusercontent.com";


//<script src="https://apis.google.com/js/platform.js" async defer></script>


/* 
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
 */



//import { MenuModule } from 'menu';
/*
const onGridReady = (params) => {
  const httpRequest = new XMLHttpRequest();
  
alert("message");       

  httpRequest.open(
    "GET",
    "https://snoopyfunctionapp.azurewebsites.net/api/GetStocks?code=Tfya2xyYCNP0ZQ3zDqESrAK9PkcLaqjAec9C1a/B1aBMF7p083ziyQ=="
  );
  httpRequest.send();
  httpRequest.onreadystatechange = () => {
    if (httpRequest.readyState === 4 && httpRequest.status === 200) {
      params.api.setRowDatasetRowData(JSON.parse(httpRequest.responseText));
    }
  };
  params.columnApi.autoSizeAllColumns();
  //animateRows=true;
  params.gridOptions = {

      defaultColDef: {
        flex: 1,
        minWidth: 100,
        resizable: true,
      },
      enableRangeSelection: true,
      allowContextMenuWithControlKey: true,
      //getContextMenuItems: getContextMenuItems
    };

var gridOptions = {

  defaultColDef: {
    flex: 1,
    minWidth: 100,
    resizable: true,
  },
  enableRangeSelection: true,
  allowContextMenuWithControlKey: true,
  getContextMenuItems: getContextMenuItems
};

};
*/

//function onGridReady(params) {
//  setGridApi(params.api);
//  setGridColumnApi(params.columnApi);
//}

const refreshGrid = () => {
  const httpRequest = new XMLHttpRequest();
  httpRequest.open(
    "GET",
    "https://snoopyfunctionapp.azurewebsites.net/api/GetStocks?code=Tfya2xyYCNP0ZQ3zDqESrAK9PkcLaqjAec9C1a/B1aBMF7p083ziyQ=="
  );
  httpRequest.send();
  httpRequest.onreadystatechange = () => {
    if (httpRequest.readyState === 4 && httpRequest.status === 200) {
      //$scope.gridOptions.api.setRowData(JSON.parse(httpRequest.responseText));
      //setRowDatasetRowData(JSON.parse(httpRequest.responseText));
    }
  };
  //params.columnApi.autoSizeAllColumns();
};

/*
getContextMenuItems = (params) => {
  var result = [
    {
      name: 'Alert ' + params.value,
      action: function () {
        window.alert('Alerting about ' + params.value);
      },
      cssClasses: ['redFont', 'bold'],
    },
    {
      name: 'Always Disabled',
      disabled: true,
      tooltip:
        'Very long tooltip, did I mention that I am very long, well I am! Long!  Very Long!',
    },
    {
      name: 'Country',
      subMenu: [
        {
          name: 'Ireland',
          action: function () {
            console.log('Ireland was pressed');
          },
          icon: createFlagImg('ie'),
        },
        {
          name: 'UK',
          action: function () {
            console.log('UK was pressed');
          },
          icon: createFlagImg('gb'),
        },
        {
          name: 'France',
          action: function () {
            console.log('France was pressed');
          },
          icon: createFlagImg('fr'),
        },
      ],
    },
    {
      name: 'Person',
      subMenu: [
        {
          name: 'Niall',
          action: function () {
            console.log('Niall was pressed');
          },
        },
        {
          name: 'Sean',
          action: function () {
            console.log('Sean was pressed');
          },
        },
        {
          name: 'John',
          action: function () {
            console.log('John was pressed');
          },
        },
        {
          name: 'Alberto',
          action: function () {
            console.log('Alberto was pressed');
          },
        },
        {
          name: 'Tony',
          action: function () {
            console.log('Tony was pressed');
          },
        },
        {
          name: 'Andrew',
          action: function () {
            console.log('Andrew was pressed');
          },
        },
        {
          name: 'Kev',
          action: function () {
            console.log('Kev was pressed');
          },
        },
        {
          name: 'Will',
          action: function () {
            console.log('Will was pressed');
          },
        },
        {
          name: 'Armaan',
          action: function () {
            console.log('Armaan was pressed');
          },
        },
      ],
    },
    'separator',
    {
      name: 'Windows',
      shortcut: 'Alt + W',
      action: function () {
        console.log('Windows Item Selected');
      },
      icon:
        '<img src="https://www.ag-grid.com/example-assets/skills/windows.png" />',
    },
    {
      name: 'Mac',
      shortcut: 'Alt + M',
      action: function () {
        console.log('Mac Item Selected');
      },
      icon:
        '<img src="https://www.ag-grid.com/example-assets/skills/mac.png"/>',
    },
    'separator',
    {
      name: 'Checked',
      checked: true,
      action: function () {
        console.log('Checked Selected');
      },
      icon:
        '<img src="https://www.ag-grid.com/example-assets/skills/mac.png"/>',
    },
    'copy',
    'separator',
    'chartRange',
  ];
  return result;
};
*/


const useStyles = makeStyles((theme) => 

createStyles({

  root: {
    flexGrow: 1,
  },

    backdrop: {
      // color: "#fff",      
      //opacity: '0',
      backgroundColor: "rgba(0,0,0,0.0)",
      zIndex: '1'
    },

  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },


  hoverButton: {
    borderRadius: 40, 
    fontSize: '25px', 
    textTransform: 'none',
    borderColor: 'white', 
    color: 'white', 
    variant: 'oulined',
    border: '2px solid',
    borderColor: 'white',
    backgroundColor: 'rgba(0,0,0,0.9)',
    // transition: '0.5s',
    '&:hover': {
      border: '2px solid',
      backgroundColor: 'rgba(77,77,77,0.7)',
      // boxShadow: "5px 10px rgba(33,33,33,0.3)"
      }
    },

    hoverButtonEmail: {
      borderRadius: 30, 
      fontSize: '16px', 
      textTransform: 'none',
      borderColor: 'white', 
      color: 'white', 
      // width: '400',
      // outlineWidth: '300',
      variant: 'oulined',
      border: '2px solid',
      borderColor: 'white',
      backgroundColor: 'rgba(0,0,0,1.0)',
      // transition: '0.5s',
      '&:hover': {
        border: '2px solid',
        backgroundColor: 'rgba(77,77,77,0.4)',
        // boxShadow: "5px 10px rgba(33,33,33,0.3)"
        }
      },

    popupCloseButton: {
      borderRadius: 40, 
      fontSize: '25px', 
      textTransform: 'none',
      borderColor: 'white', 
      color: 'white', 
      variant: 'oulined',
      border: '0px solid',
      borderColor: 'white',
      backgroundColor: 'rgba(0,0,0,0.7)',
      // transition: '0.5s',
      '&:hover': {
        border: '0px solid',
        backgroundColor: 'rgba(44,44,44,0.6)',
        // boxShadow: "5px 10px rgba(33,33,33,0.3)"
        }
      }

  // })

}),

); // makestyles


  // mybuttonstyles: {
  //   margin: theme.spacing(1),
  //   variant: 'outlined',
  //   //borderRadius: "5em"
  //   //borderradius: '23'
  //   //border: '3px solid',
  //   background: 'green',
  //   color: 'green'
  // },

  // dialog: {
  //   width: 400,
  //   height: 600,
  //   margin: '0 auto',
  //   position: 'absolute',
  //   left: 930,
  //   top: 200
    
  // }






// Or Create your Own theme:
const buttontheme = createMuiTheme({
  palette: {
    primary: {
      main: 'rgba(0,0,0,0.7)' 
      //borderColor: '#04AA6D'  
    },
 
    Button: {
    borderColor: '#000000',
    '&:hover': {
      backgroundColor: '#4CAF50'
      }
    }
  }

});
    

    // Button: {
    //   borderColor: {
    //     color: '#000000'
    //   }
    // },
    // overrides: {
    //   Button: {
    //     borderColor: {
    //       color: '#000000'
    //     }
    //   },




const darkTheme = createMuiTheme({

  // MuiButton: {
  //   root: {
  //     borderRadius: 80,
  //   }
  //  },


  palette: {
    type: 'dark',
    texttransform: 'none'
  },
});

const theme = createMuiTheme({

  // MuiButton: {
  //   root: {
  //     borderRadius: 80,
  //   }
  //  },

  palette: {
    primary: {
      main: "#ffffff", // 46AD8D
      contrastText: "#fff", //button text white instead of black
      texttransform: 'none'
    },
    background: {
      default: "#ffffff", // 394764
      texttransform: 'none'
    }
  }
});








/* 
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));  */



/*
    function setWidthAndHeight(size) {
      //var eGridDiv = document.querySelector('#myGrid');
     // eGridDiv.style.setProperty('width', size);
     // eGridDiv.style.setProperty('height', size);
     // params.gridOptions.api.doLayout();
     
    }

// setup the grid after the page has finished loading
document.addEventListener('DOMContentLoaded', function() {
  var gridDiv = document.querySelector('#myGrid');
  new agGrid.Grid(gridDiv, gridOptions);

  agGrid.simpleHttpRequest({ url: 'https://www.ag-grid.com/example-assets/olympic-winners.json' })
      .then(function(data) {
          gridOptions.api.setRowData(data);
      });
});
*/
/* 


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStylesTab = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));
 */


const addSymbolClicked = () => {
//alert('addSymbol Clicked');
//      id="myGrid"
// className="ag-theme-balham-dark"
//var eGridDiv = document.querySelector("#myGrid");
//eGridDiv.theme = "ag-theme-balham";
//eGridDiv.className = "ag-theme-balham";
};



var darkthemeselected = true;

const DarkTheme = () => {
    //      id="myGrid"
    // className="ag-theme-balham-dark"
    var eGridDiv = document.querySelector("#myGrid");
    eGridDiv.className = "ag-theme-balham-dark";
};

const LightTheme = () => {
    var eGridDiv = document.querySelector("#myGrid");
    //eGridDiv.theme = "ag-theme-balham";
    eGridDiv.className = "ag-theme-balham";
    //eGridDiv.className = "ag-theme-alpine";
    //eGridDiv.className = "ag-theme-material";

};



const ToggleTheme = () => {

  var eGridDiv = document.querySelector("#myGrid");

  /*
  if (darkthemeselected == true)
  {
    LightTheme();
    darkthemeselected = false;
  }
  else
  { 
    DarkTheme();
    darkthemeselected = true;
  }
*/


if (eGridDiv.className == "ag-theme-balham")
{
  eGridDiv.className = "ag-theme-balham-dark"
}
else
{
  eGridDiv.className = "ag-theme-balham"
}

  //var eGridDiv = document.querySelector("#myGrid");
  //eGridDiv.theme = "ag-theme-balham";
  //eGridDiv.className = "ag-theme-balham";
  //eGridDiv.className = "ag-theme-alpine";
  //eGridDiv.className = "ag-theme-material";

};






const buttonStyles = makeStyles({
  // button: {
  //   margin: theme.spacing(1),
  //   borderRadius: "5em"
  // },
  root: {
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    borderRadius: 30,
    border: 0,
    color: 'white',
    height: 48,
    padding: '0 30px',
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  },
  label: {
    textTransform: 'capitalize',
  },
  margin: {
    margin: theme.spacing(100),
  },
});


//////////////////////////////////////////////////////////
//            App
/////////////////////////////////////////////////////////

const App = () => {
  const classes = useStyles();
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);

  const [rowData, setRowData] = useState([]);

  const buttonclasses = buttonStyles();




     
//Window.FB.getLoginStatus(function(response) {
//   statusChangeCallback(response);
//});


  function onGridReady(params) {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);

   // var eGridDiv = document.querySelector("#myGrid");


    

//params.gridOptions.theme


    // params.gridoptions.eGridDiv.width = 1000;

    //eGridDiv.style.width = '71.6%';
    //eGridDiv.style.height = '2000';

    params.columnApi.autoSizeAllColumns();

    //eGridDiv.style.height = '100%';
    //params.gridOptions.api.dola
    //gridOptions.api.doLayout();
    //eGridDiv.style.set
    //eGridDiv.width = '10%';
    // eGridDiv
    //eGridDiv.style.setProperty('width', size);
    // eGridDiv.style.setProperty ('height', size);

    //params.gridOptions.api.dola

    //var allColumnIds = [];
    //params.gridColumnApi.getAllColumns().forEach(function (column) {
    //  allColumnIds.push(column.colId);
    //});
    //params.gridColumnApi.autoSizeColumns(allColumnIds, true);
    darkthemeselected = true;
    DarkTheme();
    //animateRows=true;
    params.gridOptions = {
      defaultColDef: {
        flex: 1,
        minWidth: 10,
        resizable: true
      },
      enableRangeSelection: true,
      allowContextMenuWithControlKey: true,
      animateRows: true,

      //getContextMenuItems: getContextMenuItems
    };
  }

  // const httpRequest = new XMLHttpRequest();
  // httpRequest.open(
  //   "GET",
  //   "https://snoopyfunctionapp.azurewebsites.net/api/GetStocks?code=Tfya2xyYCNP0ZQ3zDqESrAK9PkcLaqjAec9C1a/B1aBMF7p083ziyQ=="
  // );
  // httpRequest.send();
  // httpRequest.onreadystatechange = () => {
  //   if (httpRequest.readyState === 4 && httpRequest.status === 200) {
  //     //$scope.gridOptions.api.setRowData(JSON.parse(httpRequest.responseText));

  //     //setRowDatasetRowData(JSON.parse(httpRequest.responseText));

  //     setRowData(JSON.parse(httpRequest.responseText));
  //   }
  // };

  /*
    const classesTab = useStylesTab();
    const [value, setValue] = React.useState(0);
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

//params.columnApi.autoSizeAllColumns();
//gridColumnApi.autoSizeAllColumns(true);
//getContextMenuItems={this.getContextMenuItems}
//const classes = useStyles();
//style={{ background: 'transparent', boxShadow: 'none'}}
//       <AppBar  style={{ position: 'static', background: '#2E3B55' }}>
//      <AppBar  style={{ position: 'static', background: '#2E3B55' }}> // navy blue

 
<AppBar style={{ position: 'static', background: '#202020' }} >
<Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
  <Tab label="+ Add Symbol" {...a11yProps(0)} />
  <Tab label="" {...a11yProps(1)} />
  <Tab label="" {...a11yProps(2)} />
</Tabs>
</AppBar>

<TabPanel value={value} index={0}>
Item One
</TabPanel>
<TabPanel value={value} index={1}>
Item Two
</TabPanel>
<TabPanel value={value} index={2}>
Item Three
</TabPanel>

        <ThemeProvider theme={darkBaseTheme}>
        </ThemeProvider>
        
<Button onClick={() => { alert('clicked') }}>Click me</Button>


        <Button onClick={() => { DarkTheme() }}>Dark Theme</Button>
        <Button onClick={() => { LightTheme() }}>Light Theme</Button>


Theme
            <Switch
        defaultChecked
        color="default"
        inputProps={{ 'aria-label': 'checkbox with default color' }}
      />

            <Button onClick={() => { LightTheme() }}>Light Theme</Button>


import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles({
  root: {
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    borderRadius: 3,
    border: 0,
    color: 'white',
    height: 48,
    padding: '0 30px',
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
  },
  label: {
    textTransform: 'capitalize',
  },
});

export default function ClassesNesting() {
  const classes = useStyles();

  return (
    <Button
      classes={{
        root: classes.root, // class name, e.g. `classes-nesting-root-x`
        label: classes.label, // class name, e.g. `classes-nesting-label-x`
      }}
    >
      classes nesting
    </Button>
  );
}

          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            startIcon={<DeleteIcon />}>
            Delete
          </Button>

        <ButtonGroup
          style={{ position: "static", background: "#202020" }}
          variant="text"
          color="secondary"
          aria-label="text primary button group">

          <Button
            className={classes.button}
            startIcon={<AddCircleOutlineIcon />}
            style={{ textTransform: "capitalize", color: "white", border: 0 }}
            onClick={() => {
              addSymbolClicked();
            }}>
            Add Symbol
          </Button>

          <Button
            startIcon={<AddCircleOutlineIcon />}
            style={{ textTransform: "capitalize", color: "white", border: 0 }}>
            Add Column
          </Button>

          <Button
            style={{ textTransform: "capitalize", color: "white", border: 0 }}
            startIcon={<DeleteForeverIcon />}>
            Delete Symbol
          </Button>

          <Button

            startIcon={<DeleteForeverIcon />}
            style={{ textTransform: "capitalize", color: "white", border: 0 }}>
            Delete Column
          </Button>

        </ButtonGroup>

      <AgGridReact
        onGridReady={onGridReady}
        rowSelection="multiple"
        rowData={rowData}
      >
        <AgGridColumn
          pinned="left"
          field="ticker"
          resizable={true}
          sortable={true}
          filter={true}
          checkboxSelection={true}
        ></AgGridColumn>

        <AgGridColumn
          field="earningsdate"
          resizable={true}
          sortable={true}
          filter={true}
        ></AgGridColumn>

        <AgGridColumn
          field="price"
          resizable={true}
          sortable={true}
          filter={true}
        ></AgGridColumn>
        
      </AgGridReact>

<AppBar style={{ position: "absolute", color: "inherit", background: "#202020", boxShadow: "none"}}>
        

                  <Button className={classes.mybuttonstyles} padding={100} margin={50} color="inherit" variant="outlined" >SIGN UP
            </Button>


Thicker button border ---->  <Button  style={{ border: '3px solid' }} className={classes.mybuttonstyles} color="inherit" variant="outlined">LOG IN</Button>

Know when to hold 'em 
Know When to fild 'em
Stock picking made easy.

<p style={{ color:"red" }} >I am red</p>

<p style={{ color:"red" }} >Stock Picking Made Easy.</p>

button {
  margin: 0 auto;
  display: block;
}
<div>
  <button>Button</button>
</div>




    <style> 
        body { 
            font-family: -apple-system,  
                BlinkMacSystemFont, sans-serif; 
        } 
        h1 { 
            color: green; 
        } 
    </style> 

 */




const [open, setOpen] = React.useState(false);

const handleClickOpen = () => {

  
  setOpen2(!open2);
  var signinButton = document.getElementById("logonbutton");
  //var y = document.getElementById("logo");
  var signinPopup = document.getElementById("popup_box");

  var motto = document.getElementById("motto");


  // if (x.style.display === "none") {
  //   x.style.display = "block";
  // } else {
  //   x.style.display = "none";
  // }


  // if (y.style.display === "none") {
  //   y.style.display = "block";
  // } else {
  //   y.style.display = "none";
  // }
  
  // x.style.display = "none";

signinButton.style.visibility = "hidden";
signinButton.style.display = "none";

signinPopup.style.visibility = "visible";
signinPopup.style.display = "block";

motto.style.visibility = "hidden";


  setOpen(true);
};



const handleClose = () => {

  var x = document.getElementById("logonbutton");
  //var y = document.getElementById("logo");
  var y = document.getElementById("popup_box");

  // if (x.style.display === "none") {
  //   x.style.display = "block";
  // } else {
  //   x.style.display = "none";
  // }

  // if (y.style.display === "none") {
  //   y.style.display = "block";
  // } else {
  //   y.style.display = "none";
  // }




  setOpen(false);
};



const handlePopupCloseButton = () => {


  
  setOpen2(!open2);
  var signinButton = document.getElementById("logonbutton");
  //var y = document.getElementById("logo");
  var signinPopup = document.getElementById("popup_box");

  var motto = document.getElementById("motto");


  // if (x.style.display === "none") {
  //   x.style.display = "block";
  // } else {
  //   x.style.display = "none";
  // }


  // if (y.style.display === "none") {
  //   y.style.display = "block";
  // } else {
  //   y.style.display = "none";
  // }
  
  // x.style.display = "none";

signinButton.style.visibility = "visible";
signinButton.style.display = "block";

signinPopup.style.visibility = "hidden";
signinPopup.style.display = "none";

motto.style.visibility = "visible";


  setOpen(false);
};



// function Login() {

//   const [showloginButton, setShowloginButton] = useState(true);
//   const [showlogoutButton, setShowlogoutButton] = useState(false);
//   const onLoginSuccess = (res) => {
//       console.log('Login Success:', res.profileObj);
//       setShowloginButton(false);
//       setShowlogoutButton(true);
//   };

//   const onLoginFailure = (res) => {
//       console.log('Login Failed:', res);
//   };

//   const onSignoutSuccess = () => {
//       alert("You have been logged out successfully");
//       console.clear();
//       setShowloginButton(true);
//       setShowlogoutButton(false);
//   };

//   return (
//       <div>
//           { showloginButton ?
//               <GoogleLogin
//                   clientId={clientId}
//                   buttonText="Sign In"
//                   onSuccess={onLoginSuccess}
//                   onFailure={onLoginFailure}
//                   cookiePolicy={'single_host_origin'}
//                   isSignedIn={true}
//               /> : null}

//           { showlogoutButton ?
//               <GoogleLogout
//                   clientId={clientId}
//                   buttonText="Sign Out"
//                   onLogoutSuccess={onSignoutSuccess}
//               >
//               </GoogleLogout> : null
//           }
//       </div>
//   );
// }
// //export default Login;


const [showloginButton, setShowloginButton] = useState(true);
const [showlogoutButton, setShowlogoutButton] = useState(false);
const onLoginSuccess = (res) => {
    console.log('Login Success:', res.profileObj);
    setShowloginButton(false);
    setShowlogoutButton(true);
};

const onLoginFailure = (res) => {
    console.log('Login Failed:', res);
};































































const [open2, setOpen2] = React.useState(false);
const handleClose2 = () => {
  setOpen2(false);
};
const handleToggle2 = () => {
  setOpen2(!open2);
};

const TurnOffBackdrop = () => {

  setOpen2(false);

  var signinButton = document.getElementById("logonbutton");
  var signinPopup = document.getElementById("popup_box");
  var motto = document.getElementById("motto");


signinButton.style.display = "block";
signinButton.style.visibility = "visible";

signinPopup.style.visibility = "hidden";
signinPopup.style.display = "none";

motto.style.visibility = "visible";

};



const handleClickTestStuffButton = () => {

          window.FB.getLoginStatus(({ authResponse }) => 
          {
            //alert("status: " + authResponse); 
            //alert("status: " + authResponse.status); 
      

            //if (authResponse.status == 'connected') {
              


              // Window.accountService.apiAuthenticate(authResponse.accessToken).then(Window.resolve);
          //} 
          // else {
          //     resolve();
          // }
          });




// Window.FB.getLoginStatus(
//     function(response) 
//     {

//       Window.FB.statusChangeCallback(response);
        
//     }
// );



  //var x = document.getElementById("logonbutton");
  //var y = document.getElementById("logo");
  //var y = document.getElementById("popup_box");


            // auto authenticate with the api if already logged in with facebook
          // window.FB.getLoginStatus(({ authResponse }) => 
          // {

          //   if (authResponse) {
              
          //     Window.accountService.apiAuthenticate(authResponse.accessToken).then(Window.resolve);
          // } 
          // // else {
          // //     resolve();
          // // }
          // });


};


// Window.FB.Event.subscribe('auth.login', function(response) {
//   // do something with response
// });

  return (
  
 





    // <Backdrop>




    <div
      id="myGrid"
      className="ag-theme-balham-dark"
      style={{ width: "100%", height: 2000 }}
    >



<div className={classes.root}>
        <AppBar style={{ position: "absolute", color: "inherit", background: "transparent", boxShadow: "none"}}>
          <Toolbar>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu">
              {/*<MenuIcon />*/} {/* The three lines menu icon.*/}
            </IconButton>
            
            
            <img src="/images/favicon.ico" style={{ height: 30, witdh: 30}}/>
            <Typography variant="h6" className={classes.title}>tockblazer
            </Typography>


            {/* The moon theme button.. maybe say "DARK MODE"
            <Tooltip title="Theme">
              <IconButton
                className={classes.mybuttonstyles}
                onClick={() => {
                  ToggleTheme();
                }}
                aria-label="Theme">
                <Brightness4Icon />
              </IconButton>
            </Tooltip>
    
            <Button  className={classes.mybuttonstyles} color="inherit" variant="outlined">LOG IN</Button>
            */}
          </Toolbar>
        </AppBar>
</div>


      <br></br>



<OutsideAlerter>
  <div id="popup_box">


<div  
id="closebutton" 
style=
{{
  minHeight: '10px', 
  textAlign: "right", 
  display: "flex", 
  flexDirection: "row", 
  justifyContent:"right",
  }} >
 {/* classes={{ root: classes.popupCloseButton }} */}
{/* onClick={handlePopupCloseButton} */}

<IconButton 
color="primary" 
aria-label="Close" size="large"
classes={{ root: classes.popupCloseButton }}
onClick={handlePopupCloseButton}
>
<CancelOutlinedIcon fontSize="large"/>
</IconButton> 


    {/* <Button id="popupclosebutton"
        classes={{ root: classes.popupCloseButton }}
        onClick={handlePopupCloseButton}
        >
        X
    </Button> */}


</div>

  <p style={{fontfamily:"BlinkMacSystemFont", fontSize:"35px", textAlign:"center" }}> 
  <b>Sign In</b>
</p>  





<br></br>
<br></br>
<br></br>
<br></br>

<div  
id="googlebutton" 
style=
{{
  minHeight: '40px', 
  textAlign: "center", 
  display: "flex", 
  flexDirection: "row", 
  justifyContent:"center",
  }} >

    <script src="https://accounts.google.com/gsi/client"></script>
        <div id="g_id_onload"
          data-client_id="381676831704-krmph7jg7ujlhidj3jhn41a8lc54j9ka.apps.googleusercontent.com"
          data-context="signin"
          data-ux_mode="popup"
          data-login_uri="http://localhost:3000/"
          data-auto_prompt="false">
        </div>
        <div class="g_id_signin"
          data-type="standard"
          data-shape="pill"
          //data-shape="rectangular"
          data-theme="filled_black"
          data-text="continue_with"
          data-size="large"
          data-logo_alignment="right"
          data-width="300">
        </div>

        </div>
      <br></br>
      <br></br>


      <div  
id="facebookbutton" 
style=
{{
  minHeight: '40px', 
  textAlign: "center", 
  display: "flex", 
  flexDirection: "row", 
  justifyContent:"center",
  }} >


{/* <div class="fb-login-button" data-size="large" data-width="100%" data-button-type="continue_with" data-auto-logout-link="false" data-use-continue-as="false">Button</div> */}

{/* 
<div class="fb-login-button" data-width="300" data-size="large" data-button-type="continue_with" data-layout="rounded" data-auto-logout-link="true" data-use-continue-as="true"></div> */}


<div class="fb-login-button" data-width="300" data-size="large" data-button-type="continue_with" data-layout="rounded" data-auto-logout-link="true" data-use-continue-as="true"></div>

        {/* <div class="fb-login-button" data-width="100%" data-size="large" data-button-type="continue_with" data-layout="default" data-auto-logout-link="false" data-use-continue-as="false">
        </div>  */}

        </div>
{/*

        <br></br>
        <br></br>

 <div  
id="emailbuttondiv" 
style=
{{
  // minHeight: '20px', 
  // width: '400',
  textAlign: "center", 
  display: "flex", 
  flexDirection: "row", 
  justifyContent:"center",
  }} >
    <Button 
          id="emailbutton"
          style={{maxWidth: '300px',  minWidth: '300px'}}
          classes={{ root: classes.hoverButtonEmail }}

          width="300"
          // onClick={handleClickOpen}
          >
          Continue with email
    </Button>
</div> */}


    </div>    



{/* todo - since I am setting opacity to 0.0 get rid of backdrop.. it was not as easy as commenting this out: */}
<Backdrop
        className={classes.backdrop}
        open={open2}
        onClick={() => {
          // setOpen2(false);
          TurnOffBackdrop();
        }}></Backdrop>  


</OutsideAlerter>







{/* 
<Backdrop
    sx={{ color: '#fff', zIndex: '1001' }}
    open='false'
> */}


{/* 

         sx={{ color: '#fff', zIndex: '1000'}}
         open={open}
         onClick={handleClose}
       */}
        {/* <CircularProgress color="inherit" /> */}


 {/* id="motto" style={{ fontfamily:"BlinkMacSystemFont", fontSize:"90px", textAlign:"center" }}*/}
{/* <div  id="dimme">  */}

<p id="motto" style={{fontfamily:"BlinkMacSystemFont", fontSize:"90px", textAlign:"center" }}> 
    Stock picking made easy.
</p>     

{/* </div> */}


 {/* </Backdrop> */}

<div  
id="logonbutton" 
style=
{{
  minHeight: '40px', 
  textAlign: "center", 
  display: "flex", 
  flexDirection: "row", 
  justifyContent:"center",
  }} >


    <Button 
          classes={{ root: classes.hoverButton }}
          id="signinbutton"
          onClick={handleClickOpen}
          >
          Sign In
    </Button>

    <Button 
          classes={{ root: classes.hoverButton }}
          id="signinbutton"
          onClick={handleClickTestStuffButton}
          >
          Test Stuff
    </Button>

    {/* <h4>How to use Backdrop Component in ReactJS?</h4>
<Button
        variant="outlined"
        color="primary"
        onClick={() => {
          setOpen2(!open2);
        }}
>
        Backdrop Demo
</Button> */}




    {/*
  
<OutsideAlerter>

document.getElementById("popup_box");

   <button>Click outside me!</button>
</OutsideAlerter>
   */}



</div>

</div>


// </Backdrop>


  );
 
};

export default App;





