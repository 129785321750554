import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
//import { darkBaseTheme } from 'material-ui/styles'; // causes that babel compile error.
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';



//import GoogleLogin from 'react-google-login';
// or
//import { GoogleLogin } from 'react-google-login';




// const responseGoogle = (response) => {
//   console.log(response);
// }



ReactDOM.render(
  <React.StrictMode>

    <div id="foobar"
    
    className="overlay"
    
    
    >
      {/* {this.props.children} */}
      <App />


    
  </div>
  </React.StrictMode>
    ,
  document.getElementById('root')
);



const darkmuitheme = createMuiTheme({
  palette: {
    type: "dark",
  }
});


const lightmuitheme = createMuiTheme({
  palette: {
     primary: {
        light: '#fff',
        main: 'rgb(255, 255, 255)', //'rgb(23, 105, 170)'
        dark: '#000',
        red: '#ff0000'
     },
     secondary: {
       main: '#ffffff', //f44336
     },
  },
  typography: { 
     useNextVariants: true
  }
});






// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();

